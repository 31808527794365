/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

fuse-vertical-navigation-group-item
    > div
    .fuse-vertical-navigation-item-title-wrapper
    .fuse-vertical-navigation-item-title
    span {
    color: var(--fuse-secondary) !important;
}

.material-icons {
    font-family: 'Material Icons' !important;
}

.mat-button-toggle-group
    .mat-button-toggle.mat-button-toggle-checked
    .mat-button-toggle-label-content {
    color: var(--fuse-on-primary-950) !important;
}

.mat-button-toggle-button {
    background-color: var(--fuse-accent-100) !important;
}

.mat-button-toggle-checked > button {
    background-color: var(--fuse-secondary) !important;
}

mat-tab-group#sign-in-tab-group > .mat-mdc-tab-header {
    display: none !important;
}

.bg-secondary {
    background-color: var(--fuse-secondary) !important;
}

.bg-primary {
    background-color: var(--fuse-primary) !important;
}

.l-h-2 {
    line-height: 1.5;
}

.mt-home {
    margin-top: -9rem !important;
}

.text-accent {
    color: var(--fuse-secondary) !important;
}

.text-secondary-badge {
  color: var(--fuse-secondary) !important;
}

.bb-card {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}
.zoom:hover {
  transform: scale(1.02);
}